import { useContext } from "react";
import Link from "@mui/material/Link";
import PageTitle from "../components/PageTitle";
import Typography from "@mui/material/Typography";
import Form, { onSubmit as formOnSubmit, onError } from "../components/Form";
import * as Yup from 'yup';
import { getGenreAIURL, yupUsernameValidations, yupPasswordValidations, yupEmailValidations, makeRequest } from "../global";
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserContext } from "../components/UserContext";
import { getGenreAITokenURL } from "../global";

const validationSchemaShape = {
  'email': yupEmailValidations,
  'password': yupPasswordValidations,
  'username': yupUsernameValidations,
  'birthdate': Yup.string()
    .required('Birthday is required.')
    .test(
      "birthdate",
      "Sorry, you don't meet getGenre's age requirements.",
      function (value) {
        const validAge = new Date()
        validAge.setFullYear(validAge.getFullYear() - 13);
        const timeOverValidAge = dayjs(validAge).diff(value);

        return timeOverValidAge >= 0;
      }
    ),
  'gender': Yup.string()
    .required('Select your gender.'),
  'accept_terms': Yup.bool()
    .oneOf([true], 'Accepting the terms of service is required.')
};

const validationSchema = Yup.object().shape(validationSchemaShape);

export default function SignUp() {
  const userContext = useContext(UserContext);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const inputFieldNamesAndProperties = {
    'email': {
      'type': 'email',
      'autocomplete': 'email',
      'required': true
    },
    'password': {
      'type': 'password',
      'autocomplete': 'new-password',
      'required': true,
    },
    'username': {
      'type': 'text',
      'autocomplete': 'username',
      'required': true
    },
    'birthdate': {
      'type': 'date',
      'label': 'Date of Birth',
      'autocomplete': 'bday',
      'required': true,
      'sx': {
        marginBottom: "16px",
      }
    },
    'gender': {
      'type': 'radio',
      'autocomplete': 'sex',
      'label': "Gender",
      'options': ['female', 'male', 'non-binary', 'other', 'prefer not to say'],
    },
    'accept_terms': {
      'type': 'checkbox',
      'label': (<>I have read and agree to the <Link target="_blank" href="/terms-of-service">Terms of Service</Link>.</>),
      'caption': (<>To learn more about how getGenre collects, uses, shares and protects your personal data, please see <Link target="_blank" href="/privacy-policy">getGenre's Privacy Policy.</Link></>),
    }
  }

  const submitButtonProperties = {
    name: "Sign Up"
  };

  const onSuccess = async () => {
    try {
      // Even though we don't need to send credentials to get a token, we still have to set it to true in order for the
      // browser to set the cookies when returned from the server.
      const result = await makeRequest({
        url: getGenreAITokenURL,
        method: "POST",
        data: {
          grant_type: 'password',
          username: methods.getValues('username'),
          password: methods.getValues('password')
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        withCredentials: true
      });

      if (!(result.data && result.data.expires_in)) {
        throw new Error("Failed to log user in due missing access token data after signup.");
      }

      await userContext.login({secondsExpiresIn: result.data.expires_in});
      console.log("Your new account is now active!")
    } catch (error) {
      // TODO send metric/alert to track this error.
      console.error("Failed to log user in after signup.", error);
    }

    navigate("/confirm-registration");
  }

  const url = `${getGenreAIURL}/signup`;

  const onSubmit = async (formValues, e) => {
    formValues = {
      ...formValues,
      birthdate: dayjs(formValues['birthdate']).format("MM/DD/YYYY")
    };

    await formOnSubmit(formValues, e, inputFieldNamesAndProperties, submitButtonProperties, url, methods.setError, onSuccess, onError)
  }

  return (
    <>
      <PageTitle title="Sign Up" />
      <Typography sx={{marginBottom: "15px"}}>
        Perform unlimited search requests and gain access to getGenre's <Link href="/api">developer API</Link> by registering below.
      </Typography>
      <FormProvider {...methods}>
        <Form
          url={url}
          onSubmit={onSubmit}
          submitButtonProperties={submitButtonProperties}
          inputFieldNamesAndProperties={inputFieldNamesAndProperties}
        />
      </FormProvider>
      <Typography sx={{marginTop: "10px"}}>
        Have an account? <Link href="/login">Log in</Link>.
      </Typography>
    </>
  );
}
