import { useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import App from "./App";
import Home from "./routes/Home";
import About from "./routes/About";
import SignUp from "./routes/SignUp";
import LogIn from "./routes/LogIn";
import ConfirmResetPassword from "./routes/ConfirmResetPassword";
import ConfirmRegistration from "./routes/ConfirmRegistration";
import AccountVerified from "./routes/AccountVerified";
import TermsOfService from "./routes/TermsOfService";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import GenrePage from "./routes/GenrePage";
import { supportEmail } from "./global";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Account from "./routes/Account";
import API from "./routes/API";
import Projects from "./routes/Projects";
import ArtistPage from "./routes/ArtistPage";
import ArtistAlbum from "./routes/ArtistAlbum";
import ResetPassword from "./routes/ResetPassword";
import { UserContextProvider, UserContext } from './components/UserContext';
import { AppBackdrop } from "./global";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import theme from "./theme";
import PageTitle from "./components/PageTitle";

const Redirect = (props) => {
  const location = useLocation();
  let { url } = props;

  if (!url) {
    url = location.pathname.replace('/redirect/','');
  }

  window.location.href = url;

  return (
    (<Stack maxWidth='100%'>
      <PageTitle title={location.pathname.replace('/', '').replace('-', ' ')} />
      <Typography variant="h5">
        Redirecting to:
      </Typography>
      <Typography variant="h5">
        <a
          href={url}
          style={{
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          {url}
        </a>
      </Typography>
    </Stack>)
  );
};

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};

const AppRoutes = () => {
  const userContext = useContext(UserContext);

  return (
    // Important to not render any routes until the user context is bootstrapped or else it can cause unnecessary re-renders once it does bootstrap.
    // Plus, it's overall more esthetically pleasing to see the app backdrop while the UserContext is bootstrapping instead of the user initially seeing they're not logged in.
    <Stack>
      {(userContext.bootstrapped === false && <AppBackdrop open={true} backgroundColor="rgb(0,0,0,1)"/>) ||
        <Routes>
          <Route path="/" element={<App />}>
            <Route
              index
              element={<Home />}
            />
            <Route
              path="genre/:genre"
              element={<GenrePage />}
            />
            <Route path="artist/:artistName" element={<ArtistPage />}>
              <Route
                path="album/:albumName"
                element={<ArtistAlbum />}
              />
            </Route>
            <Route
              path="confirm-registration"
              element={<ConfirmRegistration />}
            />
            <Route
              path="login"
              element={<LogIn />}
            />
            <Route
              path="signup"
              element={<SignUp />}
            />
            <Route
              path="reset-password"
              element={<ResetPassword />}
            />
            <Route
              path="confirm-reset-password"
              element={<ConfirmResetPassword />}
            />
            <Route
              path="account-verified"
              element={<AccountVerified />}
            />
            {userContext.loggedIn &&
              <>
                <Route
                  path="account"
                  element={<Account />}
                />
              </>
            }
            <Route
              path="about"
              element={<About />}
            />
            <Route
              path="api"
              element={<API />}
            />
            <Route
              path="projects"
              element={<Projects />}
            />
            <Route
              path="projects/dailysetlist"
              element={<Redirect url={"https://dailysetlist.com"} />}
            />
            <Route
              path="terms-of-service"
              element={<TermsOfService />}
            />
            <Route
              path="privacy-policy"
              element={<PrivacyPolicy />}
            />
            <Route
              path="email-support"
              element={<Redirect url={"mailto:" + supportEmail} />}
            />
            <Route
              path="spotify-playlists"
              element={<Redirect url={"https://spoti.fi/3mwVQzL"} />}
            />
            <Route
              path="spotify"
              element={<Redirect url={"https://open.spotify.com/user/24n0r8vmgb6ddxtts3lpb8c9a"} />}
            />
            <Route
              path="facebook"
              element={<Redirect url={"https://www.facebook.com/getgenre"} />}
            />
            <Route
              path="twitter"
              element={<Redirect url={"https://twitter.com/getgenre"} />}
            />
            <Route
              path="redirect/*"
              element={<Redirect />}
            />
            <Route
              path="*"
              element={<Navigate to={"/"} replace />}
            />
          </Route>
        </Routes>
      }
    </Stack>
  );
}

const AppRouter = () => {
  return (
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserContextProvider>
          <ScrollToTop>
            <AppRoutes />
          </ScrollToTop>
        </UserContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default AppRouter;
