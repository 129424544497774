import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import PageTitle from "../components/PageTitle";
import dailysetlist from "../images/dailysetlist.webp";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Stack from "@mui/material/Stack";

export default function Projects() {
  return (<>
    <PageTitle title="projects" />
    <Typography sx={{ marginBottom: "30px"}}>
      Check out these awesome projects using the getGenre API:
    </Typography>
    <Stack alignItems="center">
      <Card sx={{ maxWidth: 345 }}>
        <CardActionArea
          href="/projects/dailysetlist"
          target="_blank"
          rel='noreferrer noopener'
        >
          <CardMedia
            component="img"
            height="140"
            image={dailysetlist}
            alt="dailysetlist.com"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Daily Setlist
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Daily Setlist is a music blog that uses the getGenre API to curate daily playlists across various music genres.
              It's a great source for discovering new music and expanding your musical horizons.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Stack>
    <Typography sx={{ marginTop: "30px"}}>
      We want to hear about your project! If you're using the getGenre API in a project, <Link target="_blank" rel='noreferrer noopener' href="/email-support">let us know</Link> and we'll feature it here.
    </Typography>
  </>);
}
