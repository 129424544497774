import { useContext, useEffect, useState } from "react";
import { getGenreAITokenURL } from "../global";
import Form, { setSubmissionError } from "../components/Form";
import PageTitle from "../components/PageTitle";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as Yup from 'yup';
import { UserContext } from "../components/UserContext";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from "react-router-dom";

const validationSchemaShape = {
  'username': Yup.string()
    .required('Username or email is required.'),
  'password': Yup.string()
    .required('Password is required.'),
  'remember_me': Yup.bool()
};

const validationSchema = Yup.object().shape(validationSchemaShape);

const inputFieldNamesAndProperties = {
  'username': {
    'type': 'text',
    'autocomplete': 'username',
    'label': 'Username or Email',
  },
  'password': {
    'type': 'password',
    'autocomplete': 'current-password',
    'caption': (<Link href="/reset-password">Forgot your password?</Link>),
  },
  'remember_me': {
    'type': 'checkbox',
    'label': "Remember me",
  }
};

const submitButtonProperties = {
  name: 'Log In',
};

export default function LogIn() {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [redirectURI, setRedirectURI] = useState(null);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      'username': '',
      'password': '',
      'remember_me': false,
      'grant_type': 'password',
    }
  });

  const onSuccess = async (result) => {
    try {
      if (!(result.data && result.data.expires_in)) {
        throw new Error("Failed to log in due to missing access token data.");
      }

      await userContext.login({secondsExpiresIn: result.data.expires_in, rememberMe: methods.getValues('remember_me')});

      const redirect = searchParams.get('redirect');

      // Redirect to the specified page if it's not the current login page.
      if (redirect && redirect.startsWith('/') && !redirect.startsWith(window.location.pathname)) {
        setRedirectURI(redirect);
      } else {
        setRedirectURI('/');
      }
    } catch (error) {
      // TODO Would be nice to send custom metric/alert since this generally shouldn't happen but not always a bug since
      // errors could be due to the user not having cookies enabled.
      setSubmissionError(methods.setError, submitButtonProperties.name, error.message);
    }
  };

  useEffect(() => {
    if (userContext.loggedIn && redirectURI) {
      // Important to navigate after the user is fully logged in to prevent any ill-effects on the redirected page.
      navigate(redirectURI);
    }
  }, [userContext.loggedIn, navigate, redirectURI]);

  // withCredentials is still needed in order to have the server set the session cookies.
  return (
    <>
      <PageTitle title="Log in" />
      <FormProvider {...methods}>
        <Form
          url={getGenreAITokenURL}
          submitButtonProperties={submitButtonProperties}
          onSuccess={onSuccess}
          inputFieldNamesAndProperties={inputFieldNamesAndProperties}
          headers={{'Content-Type': 'application/x-www-form-urlencoded'}}
          withCredentials={true}
        />
      </FormProvider>
      <Typography sx={{marginTop: "10px"}}>
        Need an account? <Link href="/signup">Sign up</Link>.
      </Typography>
    </>
  )
}
