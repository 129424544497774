import { createContext, useEffect, useState } from "react";
import { AppBackdrop } from "../global";
import { defaultGenreData } from "../defaultGenreData";
//import { makeRequest, spotifyManagerURL } from "../global";

const GenreDataContext = createContext();

const GenreDataContextProvider = ({ children }) => {
  const [genreData, setGenreData] = useState(null);
  const [genreContext, setGenreContext] = useState(null);

  useEffect(() => {
    if (genreData) {
      const genreKeys = Object.keys(genreData);

      let genreList = [];

      for (let i = 0; i < genreKeys.length; i++) {
        genreList.push(genreKeys[i].replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()));
      }

      setGenreContext({
        genresAndIds: genreData,
        genres: genreList,
      });
    } else {
      try {
        const localGenreData = localStorage.getItem("genreData");

        if (localGenreData) {
          setGenreData(JSON.parse(localGenreData));
        } else {
          setGenreData(defaultGenreData);
        }
      } catch{
        setGenreData(defaultGenreData);
      }

      //const fetchGenres = async () => {
      //  try {
      //    const result = await makeRequest({url: `${spotifyManagerURL}/genres_and_ids`});

      //    setGenreData(result.data);
      //    localStorage.setItem("genreData", JSON.stringify(result.data));
      //  } catch (error) {
      //    console.error("Failed to fetch genre data.");
      //  }
      //};

      //Disable for now until spotify-manager can properly support this with running into rate limits.
      //(async () => {
      //  await fetchGenres();
      //})();
    }
  }, [genreData]);

  return (
    <GenreDataContext.Provider value={genreContext}>
      <AppBackdrop open={!genreContext} />
      {children}
    </GenreDataContext.Provider>
  );
};

export { GenreDataContext, GenreDataContextProvider };
