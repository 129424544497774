import { useState } from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { colors } from "./theme";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import background from "./images/background.webp";
import Paper from '@mui/material/Paper';
import { GenreDataContextProvider } from './components/GenreDataContext';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [alertOpen, setAlertOpen] = useState(cookies.get("alertOpen") !== false);

  const goHome = () => {
    navigate("/");
  }

  const closeAlert = () => {
    setAlertOpen(false);
    cookies.set('alertOpen', false, { path: '/' });
  }

  return (
    <Stack
      sx={{
        backgroundColor: "rgb(0,0,0,1)"
      }}
    >
      <Stack
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          padding: "0",
          margin: "auto",
        }}
      >
        <Stack
          sx={{
            background: "linear-gradient(to bottom, transparent, black)",
            backgroundColor: "rgba(0,0,0,.50)",
            width: "100vw",
            // Setting this to 315px so the logo looks good at small resolutions and doesn't cause
            // jitter or overflow issues.
            minWidth: "315px",
            maxHeight: "100vh",
          }}
        >
          <Stack
            sx={{
              justifyContent: "space-between",
              minHeight: "100vh"
            }}>
            <Stack>
              <GenreDataContextProvider>
                <Nav />
                {/* Gutters are disabled because weird overflow issues was occuring. */}
                <Container maxWidth="xl" disableGutters={true}>
                  {alertOpen &&
                    <Stack sx={{alignItems: "center", marginTop: "15px"}}>
                      <Alert severity="info">
                        <Stack flexDirection="row" justifyContent="space-between" >
                          <AlertTitle>Exciting News!</AlertTitle>
                          <IconButton
                            aria-label="Close"
                            onClick={closeAlert}
                            edge="end"
                            disableRipple={true}
                            size="small"
                            sx={{padding: "0px", marginBottom: "10px"}}
                          >
                            <CloseIcon  />
                          </IconButton>
                        </Stack>
                        Our <Link href="/api">Developer API</Link> is here! Visit the new <Link href="/projects">Projects page</Link> to explore how innovators are harnessing the power of getGenre's AI.
                      </Alert>
                    </Stack>
                  }
                  <Paper
                    elevation={5}
                    sx={{
                      margin: "15px 0",
                      padding: "30px 0",
                      backgroundImage: "none",
                      backgroundColor: colors.pageBackgroundColor,
                    }}
                  >
                    <Container sx={{textAlign: "center"}}>
                      {location.pathname !== "/" &&
                        <Stack
                          sx={{
                            flexDirection: "row-reverse",
                          }}>
                          <IconButton
                            aria-label="Close"
                            onClick={goHome}
                            edge="end"
                            size="small"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Stack>
                      }
                      <Outlet />
                    </Container>
                  </Paper>
                </Container>
              </GenreDataContextProvider>
            </Stack>
            <Footer />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
