import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export default function API() {
  return (
    <>
      <Typography>
        Explore the <Link href="/projects">Projects page</Link> to discover the creative ways others are utilizing getGenre's API!
      </Typography>
      <Stack sx={{backgroundColor: 'white', height: '100vh', marginTop: '30px'}}>
        <iframe width="100%" height="100%" src="https://api.getgenre.com/docs"></iframe>
      </Stack>
    </>
  );
}
