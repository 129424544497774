import { createContext, useState, useEffect, useCallback } from "react";
import Cookies from 'universal-cookie';
import getGenreAIURL, { getGenreAITokenURL, makeRequest, AppModal } from "../global";
import { useForm } from "react-hook-form";

const UserContext = createContext();
const cookies = new Cookies();

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [runSessionReloaderEffect, setRunSessionReloaderEffect] = useState(false);
  const [reloadSession, setReloadSession] = useState(true);
  const [session, setSession] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(null);
  const [userError, setUserError] = useState(false);
  // bootstrapped essentially blocks the app from rendering until the UserContext is fully bootstrapped.
  // Three ways for the UserContext to be bootstrapped:
  // 1. The user is logged in successfully automatically.
  // 2. No session to restore upon load.
  // 3. Unable to successfully restore session upon load.
  const [bootstrapped, setBootstrapped] = useState(false);

  const getCookieSession = useCallback((sessionRequired = false) => {
    try {
      let cookieSession = {};

      // Always use the cookie to get the current session in order to determine if the session has changed, such as when
      // the user logs in on another tab.
      const sessionCookie = cookies.get("session");

      if (sessionCookie !== undefined && sessionCookie !== null) {
        if (sessionCookie.expires === undefined || sessionCookie.expires === null) {
          throw new Error("Session cookie must have an expiration to restore the session. Please log in again.");
        } else {
          cookieSession.expires = Number(sessionCookie.expires);
        }

        if (sessionCookie.rememberMe !== undefined && sessionCookie.rememberMe !== null) {
          cookieSession.rememberMe = sessionCookie.rememberMe;
        } else {
          cookieSession.rememberMe = false;
        }
      } else if (session || sessionRequired) {
        // If the session cookie is missing, but a session exists in memory, then the user either manually deleted the cookie or
        // doesn't have cookies enabled, or the cookie naturally expired indicating the session expired.
        // Thus, if users have cookies disabled, they will be logged out prematurely.
        throw new Error("Session cookie not found or expired.");
      }

      if (session && session.expires !== cookieSession.expires) {
        // This can happen when the user logs in on another tab or the session cookie was manually manipulated.
        throw new Error("Session data has changed unexpectedly.");
      }

      return cookieSession;
    } catch (error) {
      setSessionExpired(true);
      throw error;
    }
  }, [session]);

  // Restores / refreshes the user's session.
  const login = useCallback(async ({secondsExpiresIn = null, rememberMe = false} = {}) => {
    try {
      let thisSession = {};

      // Any new session data provided will overwrite the current session data.
      if (secondsExpiresIn !== undefined && secondsExpiresIn !== null) {
        const expires = secondsExpiresIn * 1000;
        // Ensures the session is refreshed before it expires.
        const expirationBuffer = 120 * 1000;

        thisSession.expires = Date.now() + expires - expirationBuffer;
        thisSession.rememberMe = rememberMe;

        let cookieExpires;

        // Set an expiration time for the cookies to ensure sessions are persisted across browser restarts.
        if (thisSession.rememberMe) {
          // getGenre AI refresh tokens are valid for 1 year, so the session cookie should be valid for 1 year.
          cookieExpires = new Date(Date.now() + 31536000000 - expirationBuffer);
        } else {
          // Rmeove the session cookie when the access token expires.
          cookieExpires = new Date(thisSession.expires);
        }
    
        cookies.set("session",
          JSON.stringify(thisSession),
          {
            path: "/",
            expires: cookieExpires
          }
        );

        const sessionCookie = cookies.get("session");

        if (sessionCookie === undefined || sessionCookie === null) {
          // Cookies are required to manage sessions properly.
          throw new Error("Cookies must be enabled to log in.");
        }
      } else {
        // session not required here since it might not exist yet.
        thisSession = getCookieSession();
      }

      if (Object.keys(thisSession).length === 0) {
        // No session, so ensure we're done bootstrapping the UserContext.
        setBootstrapped(true);
      } else {
        // Important to check even stored session data since the user could be manipulating the cookies.
        if (!Number.isInteger(thisSession.expires)) {
          throw new Error("Session expiration time must be an integer. Got: " + thisSession.expires);
        }

        if (typeof thisSession.rememberMe !== "boolean") {
          throw new Error("Session rememberMe must be a boolean. Got: " + thisSession.rememberMe);
        }

        setSession(thisSession);
      }
    } catch (error) {
      // Clear all session data to ensure any previous, and possibly erroneous, session data is removed.
      // Using state function to avoid the added useEffect dependency.
      setSessionExpired(true);
      // Let calling functions log and send any metrics/alerts since the user could be manipulating cookies and
      // causing unnecessary alerts.
      throw error;
    }
  }, [session, getCookieSession]);

  const handleRequest = useCallback(async (requestArgs, retry = true) => {
    const beforeRequestLogic = () => {
      if (sessionExpired) {
        throw new Error("Session has expired.");
      }

      // Ensure the session is still valid and hasn't been manipulated.
      getCookieSession(true);
    };

    const access_token_expired_message = "Access token has expired.";
    const unable_to_refresh_message = "Unable to refresh access token.";

    try {
      if (!session) {
        throw new Error("No session data found to make user request.");
      }

      const refresh = session.rememberMe;

      if (!requestArgs.hasOwnProperty('withCredentials')) {
        requestArgs = {...requestArgs, withCredentials: true};
      }

      try {
        if (session.expires <= Date.now()) {
          throw new Error(access_token_expired_message);
        } else {
          return await makeRequest(requestArgs, beforeRequestLogic);
        }
      } catch (error) {
        // Only need to try and refresh the session if the access token has expired or the request failed due to a 401.
        if (!refresh || !error || (!error.message.includes(access_token_expired_message) &&
            (!error.response || error.response.status !== 401))) {
          throw error;
        } else {
          let result;

          try {
            result = await makeRequest({
              url: getGenreAITokenURL,
              method: "POST",
              data: {
                grant_type: 'refresh_token',
              },
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              withCredentials: true
            }, beforeRequestLogic);
          } catch (error) {
            throw new Error(unable_to_refresh_message + " " + error);
          }

          // Update the session.
          await login({secondsExpiresIn: result.data.expires_in, rememberMe: true});

          if (retry) {
            // Although the session state may have not been updated yet, the token cookies have been refreshed and persisted,
            // so the request can be retried.
            return await makeRequest(requestArgs, beforeRequestLogic);
          }
        }
      }
    } catch (error) {
      // If the access token is expired, or unable to refresh, remove the session data.
      // Only remove the session when an error occurs that is not due to unexpected network issues or errors with the API server.
      // Can't set the session as expired for 403 errors for scenarios where the user provides the wrong password for editing their account.
      if (error &&
          ((error.message.includes(access_token_expired_message)) ||
          (error.message.includes(unable_to_refresh_message)) ||
          (error.response && error.response.status === 401))) {
        // Can't call logout here since it requires a valid session to make the request to the API. Any HTTPOnly cookies will remain until they naturally expire or get replaced.
        setSessionExpired(true);
      }

      // Important to not modify the error since it could remove response data.
      // No need to send metrics/alerts since the user might be manipulating the cookies and causing unnecessary alerts, or there is no session to begin with.
      throw error;
    }
  }, [login, session, sessionExpired, getCookieSession]);

  const removeSessionData = useCallback(() => {
    cookies.remove("session", { path: "/" });
    setSession(null);
    setSessionExpired(null);
    // In case any error during bootstrapping.
    setBootstrapped(true);
  }, []);

  // Note, if the access token has to be refreshed during a logout request, a userinfo
  // request will fire after logging out with the server. Which shouldn't generally work because the cookies
  // are removed; however, since both the logout and userinfo requests are made concurrently, the userinfo request
  // will still be made with the pre-existing cookies. This is only a problem if user data is put back into the context
  // after being removed during logout. However, testing shows that user info is updated before the session is removed, so
  // this is not a problem.
  const logout = useCallback(async (logoutWithAPI = true) => {
    if (logoutWithAPI) {
      try {
        await handleRequest({
          url: getGenreAIURL + "/logout",
          method: "POST",
        });
      } catch (error) {
        console.error("Failed to log out with the server due to the following error: " + error);
      }
    }

    // Doing here instead of in the handleRequest function to ensure the user is always logged out even if the request fails. 
    removeSessionData();
    console.log("You are now logged out.");
  }, [handleRequest, removeSessionData]);


  useEffect(() => {
    if (loggedIn === true) {
      console.log("You are now logged in.")
      setBootstrapped(true);
    } else {
      setUser(null);
    }
  }, [loggedIn])

  useEffect(() => {
    if (user && session) {
      // Important to not set loggedIn to true until the user is set to ensure the session is valid and
      // the context is fully bootstrapped.
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [user, session]);

  useEffect(() => {
    // If we're not able to get the user and we're not logged in, remove any session data in order to not
    // accidentally log the user back in when the server comes back online--security issue if the user
    // appears to be logged out but then their acccount is logged back in when the server comes online.
    // Can't call setSessionExpired directly from where setUserError is called because if the user is
    // already logged in, we don't want to remove their session--only if they reload will it be removed
    // since they then won't be logged in and the session will fail to bootstrap due to a given error.
    // Also don't want loggedIn as dependency on handleRequest() to avoid unnecessary re-renders elsewhere.
    if (userError && !loggedIn) {
      // Safe to expire the session since the user is not logged in yet.
      setSessionExpired(true);
    }
  }, [userError, loggedIn]);

  // An effect for handling a session's natural or error-induced expiration.
  useEffect(() => {
    // An existing session has expired.
    if (sessionExpired) {
      if (loggedIn) {
        const getObfuscatedUser = () => {
          const obfuscatedUser = {}

          for (const key in user) {
            obfuscatedUser[key] = "********";
          }

          return obfuscatedUser;
        }

        // Obfuscate the user's data, but don't remove it since we don't want to fully remove all the session data
        // until the user reloads the page.
        setUser(getObfuscatedUser());
        console.log("Your session has expired. Please reload the page to log in again.");
        setOpenSessionExpiredModal(true);
      } else {
        // The session, while bootstrapping or manually logging in, ran into an issue.
        // No need to notify the user since they're already aware they're not logged in.
        // Thus, we need to reset all session flags to ensure the user can try to log in again.
        removeSessionData();
      }
    }
  }, [sessionExpired, loggedIn, removeSessionData]);

  useEffect(() => {
    const sessionReloader = () => {
      if (document.visibilityState === 'visible') {
        if (reloadSession && !sessionExpired) {
          setReloadSession(false);

          (async () => {
            try {
              await login();
            } catch (error) {
              // Don't send metric/alert since the user could be manipulating the cookies and causing unnecessary alerts.
              console.error("Failed to reload session due to the following error: " + error);
            }
          })();
        }
      } else {
        setReloadSession(true);
      }
    }

    // Run the sessionReloader on the initial load of this effect, and then only when the visibility changes.
    // In dev/strict mode, the sessionReloader can be called twice, thus possibly conflicting with
    // each other when trying to refresh the session with the server. Thereofore, each concurrent run of this
    // effect will set the runSessionReloaderEffect state to true, which will trigger the sessionReloader to
    // run only once since effects are only run when their dependencies change.
    if (!runSessionReloaderEffect) {
      setRunSessionReloaderEffect(true);
    } else {
      // Always validate the session when the app becomes visible.
      document.addEventListener('visibilitychange', sessionReloader);
      sessionReloader();
    }

    return () => {
      document.removeEventListener('visibilitychange', sessionReloader);
    }
  }, [login, session, runSessionReloaderEffect, reloadSession, sessionExpired]);

  useEffect(() => {
    const updateUserInfo = async () => {
      try {
        // Don't retry the request in handleRequest if say the access token is expired since when refreshing, the user info will be fetched again and thus retrying this initial request after a 
        // successful refresh would cause the request to ultimately be made twice.
        const result = await handleRequest({
          url: getGenreAIURL + "/userinfo",
        }, false);

        if (result.data) {
          setUser(result.data);
        } else {
          // This should never happen.
          // TODO send metrics/alerts since the API is down or misbehaving.
          throw new Error("No user data in response.")
        }
      } catch (error) {
        console.error("Failed to fetch user info due to the following error: " + error);
        setUserError(true);
      }
    };

    if (session && !sessionExpired) {
      (async () => {
        await updateUserInfo();
      })();
    }
  }, [handleRequest, session, sessionExpired]);

  return (
    <UserContext.Provider value={{
      logout: logout,
      login: login,
      user: user,
      loggedIn: loggedIn,
      handleRequest: handleRequest,
      bootstrapped: bootstrapped,
    }}>
      {children}
      <AppModal
        name="session-expired"
        open={openSessionExpiredModal}
        prompt={
          {
            title: "Session Expired",
            message: "Your session has expired. Reload the page to log in again.",
          }
        }
        formProps={{
          methods: useForm(),
          onSubmit: () => {
            // Need to reload to ensure the latest updates and session data are fetched, such as when a new user logs in on another tab.
            // We can't remove any session cookies before doing so since it might have been the user who logged in on another tab; thus,
            // if the access token or refresh token is still invalid, the user will be logged out on the next reload when trying to bootstrap the user.
            window.location.reload();
          },
          submitButtonProperties: {
            name: "Reload",
          }
        }}
      />
    </UserContext.Provider>
  );
}

export { UserContext, UserContextProvider };