import { useState, useEffect } from "react";
import { toTitleCase, defaultErrorMessage, makeRequest } from "../global";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { fonts } from "../theme";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { useFormContext, Controller } from "react-hook-form";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { AppBackdrop } from "../global";

export const defaultSubmitButtonProperties = {
  name: "Submit",
};

export const setSubmissionError = (setError, key, message=defaultErrorMessage) => {
  setError(key, {type: 'error', message: message});
  console.error(message);
};

const submit = async (formValues, url, setError, onSuccess, onError, inputFieldNamesAndProperties, submitButtonProperties, headers, withCredentials, userContext) => {
  try {
    if (url) {
      const requestArgs = {
        url: url,
        method: 'POST',
        data: formValues,
        headers: headers,
        withCredentials: withCredentials
      };

      let result;

      if (userContext) {
        result = await userContext.handleRequest(requestArgs);
      } else {
        result = await makeRequest(requestArgs);
      }

      if (result) {
        if (onSuccess) {
          onSuccess(result);
        }
      } else {
        throw new Error("No response received.");
      }
    } else if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    onError(error, setError, inputFieldNamesAndProperties, submitButtonProperties);
    console.error("Failed to submit form due to the following error: " + error);
  }
};

export const onSubmit = async (formValues, e, inputFieldNamesAndProperties, submitButtonProperties, url, setError, onSuccess, onError, headers, withCredentials, userContext) => {
  let missingInputs = [];

  for (const [inputFieldName, inputFieldProperties] of Object.entries(inputFieldNamesAndProperties)) {
    let inputValue = "";

    if ((inputFieldProperties['type'] !== 'checkbox') && inputFieldName in formValues) {
      inputValue = formValues[inputFieldName];
    }

    if (!inputValue || inputValue.trim() === "") {
      missingInputs.push(inputFieldName);
    }
  }

  const totalInputs = Object.keys(inputFieldNamesAndProperties).length;

  if (totalInputs > 0 && missingInputs.length === totalInputs) {
    if (setError) {
      setSubmissionError(setError, submitButtonProperties.name, "No input provided.");
    }
  } else {
    await submit(formValues, url, setError, onSuccess, onError, inputFieldNamesAndProperties, submitButtonProperties, headers, withCredentials, userContext);
  }
};

export const processSubmissionErrors = (error, inputFieldNamesAndProperties, submitButtonProperties, setError) => {
  if (error) {
    const response = error.response;

    if (response && typeof response.data === 'object') {
      let submit_error_message = "";

      for (const [key, value] of Object.entries(response.data)) {
        if (Array.isArray(value)) {
          for (const item of value) {
            // Pydantic error response.
            if (item.hasOwnProperty('loc') && item.hasOwnProperty('msg') && item.hasOwnProperty('type')) {
              let field;

              if (item.loc.length === 0) {
                field = null;
              } else {
                field = item.loc[item.loc.length - 1];
              }
              
              let message = item.msg;

              if (item.hasOwnProperty('ctx') && item.ctx.hasOwnProperty('error') && item.ctx.error && (typeof item.ctx.error === 'string' || item.ctx.error instanceof String)) {
                message = item.ctx.error;
              }

              if (field && field in inputFieldNamesAndProperties) {
                const error_object = {
                  type: item.type,
                  message: message
                };

                setError(field, error_object);
              } else {
                if (submit_error_message) {
                  submit_error_message += " ";
                }

                submit_error_message += message;
              }
            } else { // An unexpected array of items.
              if (submit_error_message) {
                submit_error_message += " ";
              }

              submit_error_message += JSON.stringify(item);
            }
          }
        } else { // Some other value tied to a key, which should be a string ... TODO and if not sned metric/alert.
          if (key in inputFieldNamesAndProperties) {
            const error_object = {
              type: 'error',
              message: value
            }

            setError(key, error_object);
          } else {
            if (submit_error_message) {
              submit_error_message += " ";
            }

            submit_error_message += value; 
          }
        }
      }

      if (submit_error_message) {
        setSubmissionError(setError, submitButtonProperties.name, submit_error_message);
      }
    // Catch all for non-object responses, which is unexpected.
    } else if (response && response.data) {
      setSubmissionError(setError, submitButtonProperties.name, JSON.stringify(response.data));
    } else {
      // Even though there could be a error.message, it's not always useful and might be confusing to users,
      // so it's best to just display a generic message. (The console should report the error though.)
      setSubmissionError(setError, submitButtonProperties.name);
    }
  } else {
    setSubmissionError(setError, submitButtonProperties.name);
  }
}

export const onError = (error, setError, inputFieldNamesAndProperties, submitButtonProperties) => {
  processSubmissionErrors(error, inputFieldNamesAndProperties, submitButtonProperties, setError);
}

/* TODO add aria-labels/tool tips to inputs and button, especially since input my be cut off */
export default function Form(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [inputFields, setInputFields] = useState(null);
  const [submitButton, setSubmitButton] = useState(null);
  const {handleSubmit, control, setError, formState: { errors, isSubmitted, isSubmitting, isSubmitSuccessful, isValid }} = useFormContext();
  const [loading, setLoading] = useState(false);

  const submitButtonProperties = props.submitButtonProperties || defaultSubmitButtonProperties;

  useEffect(() => {
    const getInputTextFields = (inputFieldNamesAndProperties) => {
      let formInputs = []

      if (!inputFieldNamesAndProperties) {
        return formInputs;
      }

      const getInputType = (type) => {
        if (type === 'password') {
          return showPassword ? "text" : "password";
        } else {
          return type;
        }
      }

      const getInputPropsForInputType = (inputFieldProperties) => {
        const type = inputFieldProperties['type'];
        const additionalInputProps = inputFieldProperties['InputProps'];
        const adornment = inputFieldProperties['adornment'];

        const handleClickShowPassword = () => setShowPassword(!showPassword);

        const handleMouseDownPassword = () => setShowPassword(!showPassword);

        let InputProps = {};

        if (additionalInputProps) {
          InputProps = {...additionalInputProps};
        }

        if (type === 'password' && (adornment || typeof adornment === "undefined")) {
          InputProps = {
            ...InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            )
          }
        }

        return InputProps;
      }

      for (const [inputFieldName, inputFieldProperties] of Object.entries(inputFieldNamesAndProperties)) {
        const inputType = inputFieldProperties['type'];

        if (inputType === 'checkbox') {
          formInputs.push(
            <Stack
              key={inputFieldName}
              sx={{
                marginBottom: "16px",
                width: "100%",
                alignItems: "center"
              }}>
              <FormControlLabel
                required={inputFieldProperties['required']}
                control={
                  <Controller
                    control={control}
                    name={inputFieldName}
                    defaultValue={false}
                    render={({
                      field: { name, ref, value, onChange } ,
                      fieldState: { invalid }
                    }) => (
                      <Checkbox
                        id={name}
                        color="primary"
                        inputRef={ref}
                        onChange={onChange}
                        checked={value}
                        required={inputFieldProperties['required']}
                      />
                    )}
                  />
                }
                label={
                  <Typography color={errors[inputFieldName] ? 'error' : 'inherit'} sx={{marginBottom: "0"}}>
                    {inputFieldProperties['label'] || toTitleCase(inputFieldName)}
                  </Typography>
                }
              />
              {errors[inputFieldName] &&
                <FormHelperText
                  error={true}
                  sx={{textAlign: "center", marginBottom: "10px"}}
                >
                  {errors[inputFieldName].message}
                </FormHelperText>
              }
              {inputFieldProperties.caption &&
                <Typography variant="caption" sx={{maxWidth: "450px"}}>
                  {inputFieldProperties.caption}
                </Typography>
              }
            </Stack>
          );
        } else if (inputType === 'radio') {
          let fcls = []

          for (const option of inputFieldProperties['options']) {
            fcls.push(
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={toTitleCase(option)}
                slotProps={{
                  typography: {
                    sx: {
                      marginBottom: "0"
                    }
                  }
                }}
              />
            );
          }

          formInputs.push(
            <Stack
              key={inputFieldName}
              sx={{
                marginBottom: "16px"
              }}
            >
              <FormControlLabel
                required={inputFieldProperties['required']}
                labelPlacement='top'
                control={
                  <Controller
                    control={control}
                    name={inputFieldName}
                    defaultValue={null}
                    render={({ field: { onChange, value, name } }) => (
                        <RadioGroup
                          row
                          aria-labelledby={`${inputFieldName}-group-label`}
                          name={name}
                          onChange={onChange}
                          value={value}
                          sx={{justifyContent: "center"}}
                        >
                          {fcls}
                        </RadioGroup>
                    )}
                  />
                }
                label={
                  <Stack>
                    <Typography color={errors[inputFieldName] ? 'error' : 'inherit'} sx={{marginBottom: "0", fontFamily: fonts.boldFont}}>
                      {inputFieldProperties['label'] || toTitleCase(inputFieldName)}
                    </Typography>
                    {errors[inputFieldName] &&
                      <FormHelperText
                        error={true}
                      >
                        {errors[inputFieldName].message}
                      </FormHelperText>
                    }
                  </Stack>
                }
              />
              {inputFieldProperties.caption &&
                <Typography variant="caption">
                  {inputFieldProperties.caption}
                </Typography>
              }
            </Stack>
          );
        } else if (inputType === 'date') {
          formInputs.push(
            <Stack
              key={inputFieldName}
              sx={{
                width: "100%",
                maxWidth: "250px",
                marginBottom: "16px"
              }}>
              <Controller
                name={inputFieldName}
                control={control}
                defaultValue={null}
                render={({
                  field: { onChange, value, ref, name},
                  fieldState: { error, invalid }
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputRef={ref}
                      disableFuture
                      value={value}
                      name={name}
                      onChange={(date) =>
                        onChange(dayjs(date))
                      }
                      label={inputFieldProperties['label'] || toTitleCase(inputFieldName)}
                      slotProps={{
                        textField: {
                          error: invalid,
                          autoComplete: inputFieldProperties['autocomplete'],
                          required: inputFieldProperties['required'],
                          helperText: error?.message,
                          sx: inputFieldProperties['sx']
                        },
                        openPickerButton: {
                          sx: {
                            marginRight: "1px"
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Stack>
          );
        } else {
          formInputs.push(
            <Stack
              key={inputFieldName}
              sx={{
                width: "100%",
                maxWidth: "250px",
                marginBottom: "16px",
                display: inputFieldProperties['display']
              }}>
              <Controller
                name={inputFieldName}
                control={control}
                defaultValue={""}
                render={({
                  field: { onChange, value, ref, name},
                  fieldState: { error, invalid }
                }) => (
                  <Stack>
                    <TextField
                      disabled={inputFieldProperties['disabled']}
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      inputRef={ref}
                      error={invalid}
                      autoComplete={inputFieldProperties['autocomplete']}
                      required={inputFieldProperties['required']}
                      label={inputFieldProperties['label'] || toTitleCase(inputFieldName)}
                      type={getInputType(inputFieldProperties['type'])}
                      helperText={error?.message}
                      sx={inputFieldProperties['sx']}
                      slotProps={{
                        input: getInputPropsForInputType(inputFieldProperties),
                        htmlInput: inputFieldProperties['inputProps'],
                        inputLabel: inputFieldProperties['InputLabelProps']
                      }} />
                    {inputFieldProperties.caption &&
                      <Typography variant="caption" sx={{textAlign:'left'}}>
                        {inputFieldProperties.caption}
                      </Typography>
                    }
                  </Stack>
                )}
              />
            </Stack>
          );
        }
      }

      return formInputs
    }

    const getSubmitButton = (submitButtonProperties) => {
      return (
        (<Controller
          name={submitButtonProperties.name}
          control={control}
          defaultValue={null}
          render={({
            field: { name },
            fieldState: { error }
          }) => (
            <>
              <Button
                color={submitButtonProperties?.color}
                type="submit"
                disabled={submitButtonProperties?.disabled}
                variant={submitButtonProperties?.variant}
                sx={
                  {
                    ...submitButtonProperties?.sx,
                  }
                }
              >
                {name}
              </Button>
              {/* Only display the error message if the form has been submitted and is not valid, as well as not currently resubmitting. */}
              {(!isSubmitting && isSubmitted && !isSubmitSuccessful && !isValid) &&
                <Typography
                  color="error"
                  sx={{
                    fontFamily: fonts.boldFont,
                    maxWidth: "300px"
                  }}>
                  {error?.message || "Please correct any input errors."}
                </Typography>
              }
            </>
          )}
        />)
      );
    };

    setInputFields(getInputTextFields(props.inputFieldNamesAndProperties));
    setSubmitButton(getSubmitButton(submitButtonProperties));
  }, [props, control, errors, showPassword, isSubmitted, isSubmitting, isSubmitSuccessful, isValid, submitButtonProperties]);

  return (
    (<Stack
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={
        handleSubmit(
          async (formValues, e) => {
            if (!props.disableLoading) {
              setLoading(true);
            }

            if (props.onSubmit) {
              await props.onSubmit(formValues, e);
            } else {
              await onSubmit(
                formValues,
                e,
                props.inputFieldNamesAndProperties,
                submitButtonProperties,
                props.url,
                setError,
                props.onSuccess,
                (props.onError || onError),
                props.headers,
                props.withCredentials,
                props.userContext
              )
            }

            setLoading(false);
          }
        )
      }
      sx={{
        width: "100%",
        alignItems: "center",
      }}>
      {inputFields}
      {submitButton}
      <AppBackdrop open={loading} />
    </Stack>)
  );
}
