import PageTitle from "../components/PageTitle";
import Typography from "@mui/material/Typography";
import { useEffect, useContext, useState } from "react";
import { UserContext } from "../components/UserContext";

export default function AccountVerified() {
  const userContext = useContext(UserContext);
  const [ userUpdated, setUserUpdated ] = useState(false);

  useEffect(() => {
    if (!userUpdated && userContext.loggedIn) {
      (async () => {
        try {
          // TODO make this a smaller sleep.
          // sleep for 10 seconds to allow enough time for the user's account to be updated.
          await new Promise(r => setTimeout(r, 10000));
          // Refresh user session and info to reflect changes.
          await userContext.login();
          console.log("Account verification successful.");
        } catch (error) {
          // TODO send custom metric/alert since this should never happen and indicates a bug.
          console.error("Failed to refresh session after account verification.", error);
        }
      })();

      setUserUpdated(true);
    }
  }, [userContext, userUpdated]);

  return (
    <>
      <PageTitle title="Account Verified" />
      <Typography>
        Thank you for verifying your email address. All features are now available to you.
      </Typography>
    </>
  );
}
